import * as React from 'react';
import { graphql } from 'gatsby';

import { DefaultLayout } from '../layouts/DefaultLayout';

import { InitiativBewerbungsPage as InitiativBewerbungsPageShared } from '@mangoart/ezagrar/pages/initiativbewerbung';

const InitiativBewerbungsPage = (props) => {
  return (
    <DefaultLayout>
      <div className={'wrap'}>
        <InitiativBewerbungsPageShared {...props} />
      </div>
    </DefaultLayout>
  );
};

export default InitiativBewerbungsPage;

export const InitiativPageQuery = graphql`
  query {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
